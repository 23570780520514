<template>
<div >
  <div class="vs-con-loading__container" id="SalesDiffCont">
    <h4 class="card-title d-flex">{{$t('DASHBOARD.PAYED')}}
      <div class="ml-auto text-right">
        <vs-button  @click="RefreshChart()" size="small" color="primary" type="filled" icon="refresh"></vs-button>
      </div>
    </h4>   
  <LineAreaChart ref="twoyearincomechart"></LineAreaChart>
  </div>
</div>
</template>
<script>
import LineAreaChart from "./LineAreaChart.vue";
export default {
  name: "SalesDifference",
    data: () => ({
    activeLoading: false
  }),
  components: {
    LineAreaChart
  },
  methods:{
    RefreshChart(){
      this.$store.dispatch('dashboarddata/getChartData2YearIncome',{ root: true },{ root: true });
    }
  },
  mounted() {
    this.RefreshChart();
  },
  computed:{
    chartData(){
      var data = [];
      if(this.$store.state.dashboarddata.twoYearIncome.data != null)
      {
        data = this.$store.state.dashboarddata.twoYearIncome.data;
      }
      return data;
    },
    loading(){
      var loading = true;
      if(typeof(this.$store.state.dashboarddata.twoYearIncome.status) != 'undefined')
        loading = this.$store.state.dashboarddata.twoYearIncome.status.loading;
      return loading;
    }
  },
  watch:{
    chartData(value)
    {
        this.$refs.twoyearincomechart.RefreshChartData(value,{ responsive: true, maintainAspectRatio: false });
    },
    loading(value)
    {
      if(value)
      {
        this.$store.dispatch('alertqueue/showLoader', {'id':'#SalesDiffCont'});
      }
      else
      {
        this.$store.dispatch('alertqueue/hideLoader', {'id':'#SalesDiffCont'});
      }
    }
  },
  
};
</script>