<template>
<div id="OrderStats" class="vs-con-loading__container">
    <h4 class="card-title d-flex">{{$t('DASHBOARD.ORDER_STATS')}}
      <div class="ml-auto text-right">
        <vs-button  @click="LoadData()" size="small" color="primary" type="filled" icon="refresh"></vs-button>
      </div>
    </h4>   
   <PieChart ref="orderpiechart" :height="300" :chartid="'orderstatsPieChart'"></PieChart>
</div>
</template>
<script>
import PieChart from '../Charts/PieChart.vue';
import codeHelper from '../../../helper/staticCodeHelper';

export default {
  name: "OrderPieChart",
  props:[],
  components:{PieChart},
  data: function (){
     return {
       orderstats:{},
       loading:false
     }
  }, 
  mounted () {
    this.LoadData();
    
  },
  methods:{
    ...codeHelper,
    LoadData(){
      this.loading = true;
      this.$store.dispatch('accounting/getOrderStats',{ root: true },{ root: true })
       .then(response => {
          this.loading = false;
          if(this.IsNotNull(response))
          {
            this.orderstats = response;
            var series= [
              this.orderstats.finished,
              this.orderstats.new_amount,
              this.orderstats.inwork_amount,
              this.orderstats.outstanding_amount,
              this.orderstats.closed_not_finished
              ];
            
            this.$refs.orderpiechart.setSeries(series);

            var labels = [
              this.orderstats.finished_label,
              this.orderstats.new_amount_label,
              this.orderstats.inwork_amount_label,
              this.orderstats.outstanding_amount_label,
              this.orderstats.closed_not_finished_label
              ];

            this.$refs.orderpiechart.setLabels(labels);
          }
          else
          {
            this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('DASHBOARD.ORDERSTATS.ERROR.LOAD')});
          }
          
        }, 
        error =>{
          this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('DASHBOARD.ORDERSTATS.ERROR.LOAD')+' '+error});
        }); 
    },
  },
  computed:{
   
  },
  watch:{
    loading(value)
    {
      if(value)
      {
        this.$store.dispatch('alertqueue/showLoader', {'id':'#OrderStats'});
      }
      else
      {
        this.$store.dispatch('alertqueue/hideLoader', {'id':'#OrderStats'});
      }
    }   
  }
};
</script>