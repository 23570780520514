<template>
   <div>
     <apexchart :height="height" :options="chartOptions" :series="series"></apexchart>
   </div>
</template>
<script>
import codeHelper from '../../../helper/staticCodeHelper';

export default {
  name: "PieChart",
  props:['height','chartid'],
  data: function (){
     return {
        chartOptions: this.getChartOptions(['label1','label2']),
        series: [],
     }
  }, 
  mounted () {
  },
  methods:{
    ...codeHelper,
    setSeries(series){
      if(this.IsNotNull(series))
      {
        this.series = series;
      }      
    },
    setLabels(labels){
      if(this.IsNotNull(labels))
      {
        this.chartOptions = this.getChartOptions(labels);
      }      
    },
    getChartOptions(labels){
      var options = {
          chart: {
            type: 'donut',
            id: this.chartid,
          },
          colors: ['#be1f37', '#595959', '#8c7406', '#490723', '#b94456'],
          labels: labels,
          noData: {
            text: "Keine Daten",
            align: 'center',
            verticalAlign: 'middle',
            offsetX: 0,
            offsetY: 0,
            style: {
              color: undefined,
              fontSize: '14px',
              fontFamily: undefined
            }
          },
          legend:{
            show:true,
            showForSingleSeries: true,
            showForNullSeries: true,
            showForZeroSeries: true,
            position: 'bottom',
            horizontalAlign: 'center', 
            floating: false
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  name: {show:false},
                  value: {show:true},
                  total:{show:true}
                }
              }
            }
          },
          dataLabels:{
            enabled:false
          }
        };
        return options;
    }
  },
  computed:{
   
  },
  watch:{
    
  }
};
</script>