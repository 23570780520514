<template>
  <div class="vs-con-loading__container" id="LastOfferCont">
    <h4 class="card-title d-flex">{{$t('DASHBOARD.LAST_CHANGE.OFFER')}}
      <div class="ml-auto text-right">
        <vs-button  @click="LoadData()" size="small" color="primary" type="filled" icon="refresh"></vs-button>
      </div>
    </h4>
    <vs-list>
      <template v-for="(data, indextr) in lastChangedOffers" >
        <vs-list-item :key="'offer_'+indextr"  :title="data.debitor.nameMin +', '+ data.offer.bez + ' ('+data.offer.offerNumber+')'" :subtitle="DateToString(data.offer.last_change,false)">
          <vs-button  @click="NavigateTo(data.offer.id,data.offer.state)"  size="small" color="primary" type="filled" icon="open_in_new"></vs-button>
        </vs-list-item>
      </template>
    </vs-list>
  </div>
</template>
<script>

import helperMethods from '../../../helper/staticFuncHelper';
import codeHelper from '../../../helper/staticCodeHelper';
import enumHelper from '../../../helper/enumHelper';

export default {
  name: "LastOffer",
  props:[],
   data: function (){
     return {
       lastChangedOffers:[]
     }
  },
  mounted:function(){
      this.LoadData();
  },
  computed:{
    lastChangedOffers_data(){
      var data = [];
      if(this.$store.state.dashboarddata.lastChangedOffers.data != null)
      {
        data = this.$store.state.dashboarddata.lastChangedOffers.data;
      }
      return data;
    },
    loading(){
      var loading = true;
      if(typeof(this.$store.state.dashboarddata.lastChangedOffers.status) != 'undefined')
        loading = this.$store.state.dashboarddata.lastChangedOffers.status.loading;
      return loading;
    }
  },
  methods:{
    ...helperMethods,
    ...codeHelper,
    ...enumHelper,
    LoadData(){
      this.$store.dispatch('dashboarddata/getLastChangedOffers',{ root: true },{ root: true }); 
    },
    NavigateTo(id,state)
    {
      this.$router.push({ name: 'Offer', params: { offer_fk: id, editable:this.isEditOfferAllowed(state), for_partnerorder_fk:0 } });
    }
  },
  watch:{
    lastChangedOffers_data(value)
    {
      this.lastChangedOffers = value;
    },
    loading(value)
    {
      if(value)
      {
        this.$store.dispatch('alertqueue/showLoader', {'id':'#LastOfferCont'});
      }
      else
      {
        this.$store.dispatch('alertqueue/hideLoader', {'id':'#LastOfferCont'});
      }
    }
  }
};
</script>
