<script>
import { Line } from "vue-chartjs";
import codeHelper from '../../../helper/staticCodeHelper';

export default {
  extends: Line,
  data() {
    return {
      gradient: null,
      gradient2: null,
      gradient3: null,
      chartdata: {
        type: Object,
        default: null
      },
      options: {
        type: Object,
        default: null
      }
    };
  },
  methods:{
    ...codeHelper,
    RefreshChartData(data,options){

      if(this.IsNotNull(data) && this.IsNotNull(options) && !this.IsEmpty(data))
      {
        this.chartdata.labels = data.labels;
        this.chartdata.datasets = [];

        for(var i= 0; i < data.datasets.length; i++)
        {
          var dataset = {
            label: data.datasets[i].label,
            borderColor:this.GetBorderColor(i),
            data:[],
            pointBackgroundColor: "white",
            borderWidth: 2,
            pointBorderWidth: 0,
            backgroundColor: this.GetGradient(i),
            pointStrokeColors: this.GetPointStrokeColors(i),
            lineTension: 0
          };
          for(var j= 0; j< data.datasets[i].data.length; j++)
          {
            dataset.data.push(Number(data.datasets[i].data[j]));
          }
          
          this.chartdata.datasets.push(dataset);
        }
        this.options = options;
        this.renderChart(this.chartdata, this.options);
      }
    },
    GetGradient(idx)
    {
      var retVal = this.gradient;

      switch(idx)
      {
        case 0:
          retVal = this.gradient;
          break;
        case 1:
          retVal = this.gradient2;
          break;
        case 2:
          retVal = this.gradient3;
          break;
      }

      return retVal;
    },
    GetBorderColor(idx)
    {
      var retVal = "#be1f37";

      switch(idx)
      {
        case 0:
          retVal = "#be1f37";
          break;
        case 1:
          retVal = "rgba(31, 31, 31, 0.5)";
          break;
        case 2:
          retVal = "rgba(22, 96, 157, 0.5)";
          break;          
      }

      return retVal;
    },
    GetPointStrokeColors(idx)
    {
      var retVal = "rgba(190, 31, 55, 0.5)";

      switch(idx)
      {
        case 0:
          retVal = "rgba(190, 31, 55, 0.5)";
          break;
        case 1:
          retVal = "rgba(31, 31, 31, 0.5)";
          break;
        case 2:
          retVal = "rgba(22, 96, 157, 0.5)";
          break;          
      }

      return retVal;
    }
  },
  mounted() {
    this.gradient = this.$refs.canvas
      .getContext("2d")
      .createLinearGradient(0, 0, 0, 450);
    this.gradient2 = this.$refs.canvas
      .getContext("2d")
      .createLinearGradient(0, 0, 0, 450);
    this.gradient3 = this.$refs.canvas
      .getContext("2d")
      .createLinearGradient(0, 0, 0, 450);

    this.gradient3.addColorStop(0, "rgba(22, 96, 157, 0.5)");
    this.gradient3.addColorStop(0.4, "rgba(22, 96, 157, 0.25)");
    this.gradient3.addColorStop(1, "rgba(255, 255, 255, 0)");

    this.gradient2.addColorStop(0, "rgba(31, 31, 31, 0.5)");
    this.gradient2.addColorStop(0.4, "rgba(31, 31, 31, 0.25)");
    this.gradient2.addColorStop(1, "rgba(255, 255, 255, 0)");

    this.gradient.addColorStop(0, "rgba(190, 31, 55, 0.8)");
    this.gradient.addColorStop(0.4, "rgba(190, 31, 55, 0.5)");
    this.gradient.addColorStop(1, "rgba(190, 31, 55, 0.0)");

    this.chartdata.labels = ["1", "2", "3"];
    this.chartdata.datasets = [];
    this.renderChart(this.chartdata, { responsive: true, maintainAspectRatio: false });
  }
};
</script>