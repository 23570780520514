<template>
  <div class="vs-con-loading__container" id="LastContactCont">
    <h4 class="card-title d-flex">{{$t('DASHBOARD.LAST_CHANGE.CONTACT')}}
      <div class="ml-auto text-right">
        <vs-button  @click="LoadData()" size="small" color="primary" type="filled" icon="refresh"></vs-button>
      </div>
    </h4>
    <vs-list>
      <template v-for="(data, indextr) in lastChangedContacts" >
        <vs-list-item :key="'contact_'+indextr"  :title="data.debitor.nameMin +', '+ data.object_name + ', '+data.object_address.street1 +', '+data.object_address.plz + ' ' + data.object_address.city" :subtitle="DateToString(data.contact.last_change,false)">
          <vs-button  @click="NavigateTo(data.contact.id)"  size="small" color="primary" type="filled" icon="open_in_new"></vs-button>
        </vs-list-item>
      </template>
    </vs-list>
  </div>
</template>
<script>

import helperMethods from '../../../helper/staticFuncHelper';
import codeHelper from '../../../helper/staticCodeHelper';
import enumHelper from '../../../helper/enumHelper';

export default {
  name: "LastContact",
  props:[],
   data: function (){
     return {
       lastChangedContacts:[]
     }
  },
  mounted:function(){
      this.LoadData();
  },
  computed:{
    lastChangedContacts_data(){
      var data = [];
      if(this.$store.state.dashboarddata.lastChangedContacts.data != null)
      {
        data = this.$store.state.dashboarddata.lastChangedContacts.data;
      }
      return data;
    },
    loading(){
      var loading = true;
      if(typeof(this.$store.state.dashboarddata.lastChangedContacts.status) != 'undefined')
        loading = this.$store.state.dashboarddata.lastChangedContacts.status.loading;
      return loading;
    }
  },
  methods:{
    ...helperMethods,
    ...codeHelper,
    ...enumHelper,
    LoadData(){
      this.$store.dispatch('dashboarddata/getLastChangedContacts',{ root: true },{ root: true }); 
    },
    NavigateTo(id)
    {
      this.$router.push({ name: 'DebitorContact', params: { debitorcontact_fk: id } });
    }
  },
  watch:{
    lastChangedContacts_data(value)
    {
      this.lastChangedContacts = value;
    },
    loading(value)
    {
      if(value)
      {
        this.$store.dispatch('alertqueue/showLoader', {'id':'#LastContactCont'});
      }
      else
      {
        this.$store.dispatch('alertqueue/hideLoader', {'id':'#LastContactCont'});
      }
    }
  }
};
</script>
