<template>
  <div class="vs-con-loading__container" id="LastPartnerOrderCont">
    <h4 class="card-title d-flex">{{$t('DASHBOARD.LAST_CHANGE.PARTNERORDER')}}
      <div class="ml-auto text-right">
        <vs-button  @click="LoadData()" size="small" color="primary" type="filled" icon="refresh"></vs-button>
      </div>
    </h4>
    <vs-list>
      <template v-for="(data, indextr) in lastChangedPartnerOrder" >
        <vs-list-item :key="'partnerorder_'+indextr"  :title="data.bez + ' ('+ data.order_nr + ')'" :subtitle="DateToString(data.last_change,false)">
          <vs-button  @click="NavigateTo(data.id,data.state)"  size="small" color="primary" type="filled" icon="open_in_new"></vs-button>
        </vs-list-item>
      </template>
    </vs-list>
  </div>
</template>
<script>

import helperMethods from '../../../helper/staticFuncHelper';
import codeHelper from '../../../helper/staticCodeHelper';
import enumHelper from '../../../helper/enumHelper';

export default {
  name: "LastPartnerOrder",
  props:[],
   data: function (){
     return {
       lastChangedPartnerOrder:[]
     }
  },
  mounted:function(){
      this.LoadData();
  },
  computed:{
    lastChangedPartnerOrder_data(){
      var data = [];
      if(this.$store.state.dashboarddata.lastChangedPartnerOrder.data != null)
      {
        data = this.$store.state.dashboarddata.lastChangedPartnerOrder.data;
      }
      return data;
    },
    loading(){
      var loading = true;
      if(typeof(this.$store.state.dashboarddata.lastChangedPartnerOrder.status) != 'undefined')
        loading = this.$store.state.dashboarddata.lastChangedPartnerOrder.status.loading;
      return loading;
    }
  },
  methods:{
    ...helperMethods,
    ...codeHelper,
    ...enumHelper,
    LoadData(){
      this.$store.dispatch('dashboarddata/getLastChangedPartnerOrder',{ root: true },{ root: true }); 
      this.$store.dispatch('company/getCompaniesOrderPossible',{ root: true },{ root: true });
    },
    NavigateTo(id,state)
    {
      this.$router.push({ name: 'PartnerOrder', params: { partnerorder_fk: id, editable:this.isEditOfferAllowed(state) } });
    }
  },
  watch:{
    lastChangedPartnerOrder_data(value)
    {
      this.lastChangedPartnerOrder = value;
    },
    loading(value)
    {
      if(value)
      {
        this.$store.dispatch('alertqueue/showLoader', {'id':'#LastPartnerOrderCont'});
      }
      else
      {
        this.$store.dispatch('alertqueue/hideLoader', {'id':'#LastPartnerOrderCont'});
      }
    }
  }
};
</script>
