<template>
<div id="UnreadMessages" class="vs-con-loading__container">
  <div slot="media" >
    <vs-row vs-justify="center">
      <vs-icon icon="email" size="large" color="primary" @click="NavigateTo" class=" cursor-pointer"></vs-icon>
    </vs-row>
  </div>
  <div>
    <vs-row vs-justify="center">
      <h1 @click="NavigateTo"  class=" cursor-pointer">{{number}}</h1>
    </vs-row>
  </div>
</div>
</template>
<script>

import codeHelper from '../../../helper/staticCodeHelper';

export default {
  name: "UnreadMessages",
  props:[],
   data: function (){
     return {
       number:0,
       loading:false
     }
  },
  mounted:function(){
      this.LoadData();
  },
  computed:{
  },
  methods:{
    ...codeHelper,
    LoadData(){
      this.loading = true;
      this.$store.dispatch('messages/getNrUnreadMessages',{ root: true },{ root: true })
       .then(response => {
          if(this.IsNotNull(response))
          {
            this.number = response;
          }
          else
          {
            this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('DASHBOARD.UNREAD_MESSAGES.ERROR.LOAD')});
          }
          this.loading = false;
        }, 
        error =>{
          this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('DASHBOARD.UNREAD_MESSAGES.ERROR.LOAD')+' '+error});
          this.loading = false;
        }); 
    },
    NavigateTo()
    {
      this.$router.push({ name: 'Messages'});
    }
  },
  watch:{
    loading(value)
    {
      if(value)
      {
        this.$store.dispatch('alertqueue/showLoader', {'id':'#UnreadMessages'});
      }
      else
      {
        this.$store.dispatch('alertqueue/hideLoader', {'id':'#UnreadMessages'});
      }
    }
  }
};
</script>
