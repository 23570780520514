<template>
  <div>
    <vs-row vs-justify="center">
      <can I="edit" a="debitors"> 
        <vs-col
          type="flex"
          vs-justify="center"
          vs-align="center"
          vs-lg="4"
          vs-sm="12"
          vs-xs="12"
        >
          <vs-card class="cardx">
            <LastContact ></LastContact>
            <div slot="footer">
              <vs-row vs-justify="flex-end">
                <vs-button color="primary" icon="add" @click="AddNewDebitorContact">{{$t('DASHBOARD.NEW_CONTACT')}}</vs-button>
              </vs-row>
            </div>
          </vs-card>
        </vs-col>
      </can>
      <can I="edit" a="offer">      
        <vs-col
          type="flex"
          vs-justify="center"
          vs-align="center"
          vs-lg="4"
          vs-sm="12"
          vs-xs="12"
        >
          <vs-card class="cardx">
            <LastOffer ></LastOffer>
          </vs-card>
        </vs-col>
      </can>
      <can I="edit" a="order">
        <vs-col
          type="flex"
          vs-justify="center"
          vs-align="center"
          vs-lg="4"
          vs-sm="12"
          vs-xs="12">
          <vs-card class="cardx" >
            <LastOrder ></LastOrder>
          </vs-card>
        </vs-col>
      </can>
    </vs-row>
    <can I="edit" a="order"> 
      <vs-row vs-justify="center">
        <vs-col
            type="flex"
            vs-justify="center"
            vs-align="center"
            vs-lg="4"
            vs-md="6"
            vs-sm="12"
            vs-xs="12"
          >
            <vs-card class="cardx">
              <OfferPieChart></OfferPieChart>
            </vs-card>
        </vs-col>
        <vs-col
          type="flex"
          vs-justify="center"
          vs-align="center"
          vs-lg="4"
          vs-md="6"
          vs-sm="12"
          vs-xs="12"
        >
          <vs-card class="cardx">
            <OrderPieChart></OrderPieChart>
          </vs-card>
        </vs-col>
        <vs-col
          type="flex"
          vs-justify="center"
          vs-align="center"
          vs-lg="4"
          vs-md="6"
          vs-sm="12"
          vs-xs="12"
        >
          <vs-card class="cardx">
            <PayedInvoicesBarChart></PayedInvoicesBarChart>
          </vs-card>
          
        </vs-col>
      </vs-row>
    </can>
    <vs-row vs-justify="center">
      <vs-col
        type="flex"
        vs-justify="center"
        vs-align="center"
        vs-lg="2"
        vs-md="6"
        vs-sm="12"
        vs-xs="12"
      >
        <vs-card class="cardx">
          <UnreadMessages></UnreadMessages>
        </vs-card>
        <vs-card class="cardx">
          <DownloadsDash></DownloadsDash>
        </vs-card>
      </vs-col>      
    
      <can I="edit" a="order"> 
        <vs-col
          type="flex"
          vs-justify="center"
          vs-align="center"
          vs-lg="6"
          vs-sm="12"
          vs-xs="12"
          code-toggler
        >
          <vs-card class="cardx">
            <SalesDifference ref="salesdiff" />
          </vs-card>
        </vs-col>
      </can>
      <can I="edit" a="partnerorder"> 
        <vs-col
          type="flex"
          vs-justify="left"
          vs-align="left"
          vs-lg="4"
          vs-sm="12"
          vs-xs="12"
        >
          <vs-card class="cardx">
            <LastPartnerOrder ></LastPartnerOrder>
            <div slot="footer">
              <vs-row vs-justify="flex-end">
                <vs-button color="primary" icon="add" @click="AddPartnerOrder">{{$t('DASHBOARD.NEW_PARTNERORDER')}}</vs-button>
              </vs-row>
            </div>
          </vs-card>
        </vs-col>  
      </can> 
    </vs-row>
    <AddDebitorContactDlg ref="addDebitorContactDlg" :SelectDebAndObjOnly="true" v-on:DebAndObjSelected="NavigateTo" v-on:Accepted="NavigateToById"></AddDebitorContactDlg>
    <AddPartnerOrderDlg ref="addPartnerOrderDlg" v-on:Success="NavigateToPartnerOrderById"></AddPartnerOrderDlg>
  </div>
</template>
<script>

import SalesDifference from "./components/dashboard/SalesDifference";
import LastOffer from "./components/dashboard/LastOffer.vue";
import LastOrder  from "./components/dashboard/LastOrder.vue";
import LastContact from "./components/dashboard/LastContact.vue";
import AddDebitorContactDlg from './components/dialogs/AddDebitorContactDlg.vue';
import codeHelper from '../helper/staticCodeHelper';
import helperMethods from '../helper/staticFuncHelper';
import UnreadMessages  from "./components/dashboard/UnreadMessages";
import DownloadsDash  from "./components/dashboard/DownloadsDash";
import OrderPieChart from "./components/dashboard/OrderPieChart.vue";
import OfferPieChart from "./components/dashboard/OfferPieChart.vue";
import PayedInvoicesBarChart from  "./components/dashboard/PayedInvoicesBarChart.vue";
import AddPartnerOrderDlg from "./components/dialogs/AddPartnerOrderDlg";
import LastPartnerOrder from "./components/dashboard/LastPartnerOrder";
export default {
  name: "Dashboard",
  components: {
    LastOffer,
    LastOrder,
    SalesDifference,
    LastContact,
    AddDebitorContactDlg,
    UnreadMessages,
    OrderPieChart,
    OfferPieChart,
    PayedInvoicesBarChart,
    AddPartnerOrderDlg,
    LastPartnerOrder,
    DownloadsDash
  },
  data: function (){
      return {
      };
  },
  mounted:function(){
      this.$store.dispatch('alertqueue/hideLoader', {'id':'none'});
  },
  computed:{
    all_loaded(){
      return this.$store.state.maincontainer.loadingstatus.all_loaded;
    },
  },
  methods:{
    ...codeHelper,
    ...helperMethods,
    AddNewDebitorContact(){
      this.$refs.addDebitorContactDlg.showDlg();
    },
    AddPartnerOrder(){
      this.$refs.addPartnerOrderDlg.ShowDlg();
    },    
    NavigateToById(id)
    {
      if(id > 0)
      {
        this.$router.push({ name: 'DebitorContact', params: { debitorcontact_fk: id } });
      }
      
    },
    NavigateToPartnerOrderById(id)
    {
      if(id > 0)
      {
        this.$router.push({ name: 'PartnerOrder', params: { partnerorder_fk: id, editable:true } });
      }
      
    },
    NavigateTo(debitor_fk,debitorobject_fk)
    {
      this.$store.dispatch('debitorcontact/getDebitorContactId', {debitor_fk:debitor_fk,debitorobject_fk:debitorobject_fk})
      .then(contact_fk => {
        
        if(this.IsNotNull(contact_fk))
        {
          if(contact_fk > 0)
          {
            this.$router.push({ name: 'DebitorContact', params: { debitorcontact_fk: contact_fk } });
          }
          else
          {
              this.$vs.dialog({
              type:'alert',
              color: 'danger',
              title: this.$t('COM.ERROR.TITEL'),
              acceptText: this.$t('COM.OK'),
              text: this.$t('DASHBOARD.DEBITORCONTACT_NOT_FOUND')
            });
          }
        }
        else
        {
            this.$vs.dialog({
              type:'alert',
              color: 'danger',
              title: this.$t('COM.ERROR.TITEL'),
              acceptText: this.$t('COM.OK'),
              text: this.$t('DASHBOARD.DEBITORCONTACT_ERROR')
            });
        }
      }, 
      error =>{

          this.$vs.dialog({
            type:'alert',
            color: 'danger',
            title: this.$t('COM.ERROR.TITEL'),
            acceptText: this.$t('COM.OK'),
            text: error
          });
      });
      
    }

  },
  watch:{
    all_loaded(value)
    {
      if(value == true)
      {
        this.$refs.salesdiff.RefreshChart();
      }
    }
  }
};
</script>
