<template>
<div id="PayedInvoices" class="vs-con-loading__container">
    <h4 class="card-title d-flex">{{$t('DASHBOARD.PAYEDINVOICES')}}
      <div class="ml-auto text-right">
        <vs-button  @click="LoadData()" size="small" color="primary" type="filled" icon="refresh"></vs-button>
      </div>
    </h4>   
   <BarChartHorizontal ref="barChartHorizontal" :height="300" :chartid="'barChartHorizontalId'"></BarChartHorizontal>
</div>
</template>
<script>
import BarChartHorizontal from '../Charts/BarChartHorizontal.vue';
import codeHelper from '../../../helper/staticCodeHelper';

export default {
  name: "PayedInvoicesBarChart",
  props:[],
  components:{BarChartHorizontal},
  data: function (){
     return {
       invoicestats:{},
       loading:false
     }
  }, 
  mounted () {
    this.LoadData();
    
  },
  methods:{
    ...codeHelper,
    LoadData(){
      this.loading = true;
      this.$store.dispatch('accounting/getInvoiceStats',{ root: true },{ root: true })
       .then(response => {
          this.loading = false;
          if(this.IsNotNull(response))
          {
            this.invoicestats = response;

            var series= [];
            this.invoicestats.serials.forEach(element => {
              var data = {name:element.name, data:element.values};
              series.push(data);
            });
            
            this.$refs.barChartHorizontal.setSeries(series);

            var labels = this.invoicestats.categories;

            this.$refs.barChartHorizontal.setLabels(labels);
          }
          else
          {
            this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('DASHBOARD.PAYEDINVOICES.ERROR.LOAD')});
          }
          
        }, 
        error =>{
          this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('DASHBOARD.PAYEDINVOICES.ERROR.LOAD')+' '+error});
        }); 
    },
  },
  computed:{
   
  },
  watch:{
    loading(value)
    {
      if(value)
      {
        this.$store.dispatch('alertqueue/showLoader', {'id':'#PayedInvoices'});
      }
      else
      {
        this.$store.dispatch('alertqueue/hideLoader', {'id':'#PayedInvoices'});
      }
    }   
  }
};
</script>